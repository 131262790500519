/* DocumentBuilderPage.css */
/* Base styles */
.document-builder-page {
  font-family: "Helvetica Neue", Arial, sans-serif;
  background: linear-gradient(135deg, #f3f4f6, #ffffff);
  color: #333;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

/* Action header for both mobile and desktop */
.action-header {
  background-color: white;
  padding: 1.25rem;
  border-bottom: 1px solid #eaeaea;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.desktop-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 15px;
}

.mobile-header h2 {
  margin: 0 0 10px 0;
}

.desktop-header h2 {
  margin: 0;
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.desktop-header .action-buttons {
  display: flex;
  gap: 12px;
}

/* Layout container */
.layout-container {
  display: flex;
  flex: 1;
  height: calc(100vh - 80px); /* Subtract header height */
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

/* Template gallery styles - REDESIGNED */
.template-gallery {
  flex: 0 0 220px;
  border-radius: 8px;
  padding: 1.25rem;
  overflow-y: auto;
  background: white;
  z-index: 5;
  box-sizing: border-box;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  margin-right: 20px;
}

.template-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.85rem;
}

.template-button {
  border: none;
  border-radius: 8px;
  padding: 14px 18px;
  text-align: left;
  cursor: pointer;
  background: white;
  color: #333;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  border: 1px solid #f0f0f0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.template-button:hover {
  background: #fcfcfc;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
  border-color: #e6e6e6;
}

.template-button.selected {
  background: #f5f9ff;
  border: 1px solid #007bff;
  color: #007bff;
}

.template-name {
  font-size: 0.95rem;
  font-weight: 500;
}

.check-icon {
  font-size: 16px;
  color: #007bff;
  margin-left: 8px;
}

.template-gallery-header {
  margin-top: 0;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  color: #333;
  font-weight: 600;
  letter-spacing: -0.01em;
}

/* Editor container styles */
.editor-container {
  position: relative;
  display: block;
  min-height: 400px;
  overflow: visible !important;
  box-sizing: border-box;
  background: white;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  border-radius: 8px;
}

.editor-container-desktop {
  flex: 1;
  width: calc(100% - 240px); /* Subtract sidebar width + margin */
  height: calc(100vh - 120px); /* Full height minus header and padding */
  padding: 0;
  margin: 0;
}

.editor-container-mobile {
  width: 100%;
  height: calc(100vh - 150px); /* Full height minus header and padding */
  margin: 0;
  padding: 0;
}

/* Fix GrapesJS editor visibility */
.gjs-editor {
  position: relative !important;
  overflow: visible !important;
}

.gjs-cv-canvas {
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
}

/* Fix for canvas sizing */
.gjs-frame-wrapper {
  padding: 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: flex-start !important;
  overflow: visible !important;
}

.gjs-frame {
  width: 100% !important;
  max-width: 840px !important;
  margin: 0 auto !important;
  border: none !important;
  box-shadow: 0 0 5px rgba(0,0,0,0.1) !important;
}

/* Action buttons */
.action-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.mobile-header .action-buttons {
  width: 100%;
}

.action-btn {
  padding: 12px 18px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  flex: 1;
  min-width: 100px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  transition: all 0.2s ease;
  box-shadow: 0 2px 6px rgba(0, 123, 255, 0.2);
}

.action-btn:hover {
  background-color: #0069d9;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
}

.action-btn:active {
  transform: translateY(0);
}

.template-selector-btn {
  background-color: #6c6cff;
}

.template-selector-btn:hover {
  background-color: #5858e6;
  box-shadow: 0 4px 10px rgba(108, 108, 255, 0.3);
}

.format-btn {
  background-color: #6c6cff;
}

.format-btn:hover {
  background-color: #5252e0;
}

.dropdown {
  position: relative;
  flex: 1;
  min-width: 100px;
}

.dropdown-btn {
  width: 100%;
  background-color: #2e7d32;
  box-shadow: 0 2px 6px rgba(46, 125, 50, 0.2);
}

.dropdown-btn:hover {
  background-color: #1b5e20;
  box-shadow: 0 4px 10px rgba(46, 125, 50, 0.3);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 100%;
  box-shadow: 0px 10px 20px rgba(0,0,0,0.12);
  z-index: 1000;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #f0f0f0;
  margin-top: 6px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content button {
  width: 100%;
  padding: 14px 16px;
  text-align: left;
  background: white;
  border: none;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.dropdown-content button:last-child {
  border-bottom: none;
}

.dropdown-content button:hover {
  background-color: #f8f9fa;
  color: #007bff;
  padding-left: 20px;
}

.error-message {
  padding: 0.75rem;
  color: white;
  background-color: #f44336;
  border-radius: 8px;
  margin-top: 10px;
}

/* Modal styles */
.parse-progress-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.parse-progress-content {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  width: 90%;
  max-width: 450px;
  box-shadow: 0 10px 25px rgba(0,0,0,0.2);
}

.parse-progress-content h3 {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 20px;
  color: #333;
}

.progress-bar-container {
  width: 100%;
  height: 12px;
  background-color: #f0f0f0;
  border-radius: 6px;
  overflow: hidden;
  margin: 20px 0;
}

.progress-bar {
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.3s ease;
}

.optimization-status {
  font-size: 14px;
  color: #666;
  margin-top: 8px;
}

.parse-modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.btn-cancel {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  color: #333;
  cursor: pointer;
}

.btn-apply {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.btn-disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.close-templates-btn {
  position: sticky;
  bottom: 15px;
  width: 100%;
  margin-top: 15px;
  background-color: #f0f0f0;
  color: #333;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  padding: 12px 0;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.close-templates-btn:hover {
  background-color: #e6e6e6;
  transform: translateY(-2px);
}

/* Loading overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Fix for GrapesJS container and editor */
.gjs-editor-cont {
  position: static !important;
  height: 100% !important;
  width: 100% !important;
  border-radius: 8px !important;
  overflow: hidden;
}

/* Fix for canvas and frame width */
div[style*="max-width: 8.5in"] {
  max-width: 840px !important;
  margin: 0 auto !important;
  width: 100% !important;
  box-sizing: border-box !important;
}

/* Mobile zoom controls */
.mobile-zoom-controls {
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 2000;
  display: flex;
  gap: 10px;
}

.zoom-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.zoom-btn:active {
  transform: scale(0.95);
}

/* Export options */
.export-btn {
  background-color: #28a745;
  padding: 10px 16px;
}

.export-btn:hover {
  background-color: #218838;
}

/* Page overflow detection and visualization styles */
.resume-container {
  position: relative;
  background-color: white;
  box-sizing: border-box;
  min-height: 11in !important; /* Ensure minimum height of one page */
  height: auto !important; /* Let it grow as needed */
  overflow: visible !important; /* Don't clip content */
}

/* Page break visualization for multi-page resumes */
.resume-container[data-multi-page="true"] {
  position: relative;
  border-bottom: none !important;
  padding-bottom: 1in !important; /* Add padding after content */
}

/* Page break indicator styles - enhanced visibility */
.page-break-indicator {
  width: 100%;
  border-top: 2px dashed #cc0000 !important; /* Red dashed line */
  margin: 0;
  padding: 0;
  height: 0 !important;
  position: absolute !important;
  left: 0 !important;
  z-index: 1000 !important;
  pointer-events: none !important;
}

.page-break-label {
  position: absolute !important;
  right: 10px !important;
  top: -10px !important;
  background: #ffffff !important;
  color: #cc0000 !important;
  font-size: 11px !important;
  padding: 2px 8px !important;
  border-radius: 10px !important;
  border: 1px solid #cc0000 !important;
  font-weight: bold !important;
  z-index: 1001 !important;
  pointer-events: none !important;
}

/* Page break lines at standard US letter height intervals */
.page-break-line-1 {
  top: 1056px !important; /* 11in * 96dpi = 1056px */
}

.page-break-line-2 {
  top: 2112px !important; /* 11in * 2 * 96dpi = 2112px */
}

.page-break-line-3 {
  top: 3168px !important; /* 11in * 3 * 96dpi = 3168px */
}

/* Force main content div to grow with content */
div[style*="max-width: 8.5in"] {
  height: auto !important;
  min-height: 11in !important;
  overflow: visible !important;
}

/* Page overflow indicator in header */
.page-overflow-indicator {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 13px;
  color: #495057;
  margin-left: 12px;
}

.page-count {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  margin-left: 8px;
}

.multi-page-warning {
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  padding: 8px 12px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.multi-page-warning-icon {
  margin-right: 8px;
  font-size: 16px;
}

/* Ensure page break visualization works in the editor */
.gjs-frame {
  overflow: visible !important;
}

/* Print styles for page breaking */
@media print {
  .page-break-indicator,
  .page-overflow-indicator,
  .multi-page-warning {
    display: none !important;
  }
  
  .resume-container {
    page-break-inside: auto !important;
  }
  
  h1, h2, h3, h4 {
    page-break-after: avoid !important;
  }
  
  li, img, table {
    page-break-inside: avoid !important;
  }
  
  @page {
    size: letter;
    margin: 0.5in;
  }
}

/* Mobile specific styles - IMPROVED for better responsiveness */
@media (max-width: 768px) {
  /* Force white background for all containers */
  .document-builder-page, 
  .layout-container,
  .editor-container,
  .editor-container-mobile,
  .gjs-cv-canvas,
  .gjs-frame-wrapper,
  .gjs-editor-cont,
  .gjs-frame {
    background: white !important;
  }
  
  /* Set absolute dimensions for all parent containers */
  .document-builder-page, 
  .layout-container {
    width: 100vw !important;
    max-width: 100vw !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow-x: hidden !important;
  }
  
  /* Make header buttons more touch-friendly */
  .action-header {
    padding: 15px;
  }
  
  .action-btn {
    padding: 14px;
    font-size: 15px;
  }
  
  /* CRITICAL: Reset container, frame and canvas positions */
  .editor-container-mobile {
    width: 100vw !important;
    max-width: 100vw !important;
    height: calc(100vh - 170px) !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow-y: auto !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  
  /* CRITICAL: Transform the frame to fill width while keeping scale */
  .gjs-frame {
    width: 180vw !important;  /* Increased from 100vw to compensate for scaling */
    max-width: 180vw !important;
    height: 325vh !important;  /* ADDED: Increased height for full content visibility */
    min-height: 325vh !important;  /* ADDED: Ensure minimum height */
    max-height: none !important;  /* ADDED: Remove height restrictions */
    transform: scale(0.55) !important;
    transform-origin: top left !important; /* Changed from top center to top left */
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
    border: none !important;
    left: 0 !important;
  }
  
  /* Ensure the canvas takes full width */
  .gjs-cv-canvas {
    width: 100vw !important;
    max-width: 100vw !important;
    top: 0 !important;
    left: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;  /* ADDED: Prevent content clipping */
  }
  
  /* Reset frame wrapper to remove padding and center alignment */
  .gjs-frame-wrapper {
    width: 100vw !important;
    max-width: 100vw !important;
    padding: 0 !important;
    margin: 0 !important;
    display: block !important; /* Changed from flex to block */
    justify-content: flex-start !important;
    align-items: flex-start !important;
    overflow: visible !important;  /* ADDED: Prevent content clipping */
  }
  
  /* Fix editor container to ensure it takes full width */
  .gjs-editor-cont {
    position: static !important;
    height: 100% !important;
    width: 100vw !important;
    max-width: 100vw !important;
    border-radius: 0 !important;
    overflow: visible !important;
  }
  
  /* Make sure the resume content fills the width */
  div[style*="max-width: 8.5in"] {
    width: 180vw !important;  /* Increased to compensate for the scale */
    max-width: 180vw !important;
    min-height: 325vh !important;  /* ADDED: Ensure content has enough height */
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    box-sizing: border-box !important;
  }
  
  /* Ensure each column takes full available width */
  div[style*="max-width: 8.5in"] > div[style*="display: flex"] {
    width: 180vw !important;  /* Increased to compensate for the scale */
    max-width: 180vw !important;
    flex-direction: row !important;
  }
  
  /* CRITICAL: Ensure all content within resume is visible */
  div[style*="max-width: 8.5in"] > div,
  div[style*="max-width: 8.5in"] * {
    overflow: visible !important;  /* ADDED: Prevent content clipping */
  }
  
  /* Add more bottom space for scrolling */
  .editor-container-mobile {
    padding-bottom: 100px !important;  /* ADJUSTED: Better space for scrolling */
  }
  
  /* Fix mobile template gallery */
  .template-gallery-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: white;
    height: 100vh;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  
  .template-gallery-mobile .template-buttons {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 70px;
  }
  
  .template-gallery-mobile .template-button {
    margin-bottom: 10px;
  }
  
  /* Dropdown fixes for mobile */
  .dropdown {
    position: static;
  }
  
  .dropdown-content {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    max-height: 50vh;
    overflow-y: auto;
  }
  
  .dropdown-content button {
    padding: 16px;
    font-size: 16px;
  }
  
  /* Fix template gallery position */
  .template-gallery-mobile {
    padding-top: 70px;
  }
  
  /* Make dropdown taps work properly on mobile */
  .dropdown:active .dropdown-content,
  .dropdown:focus .dropdown-content,
  .dropdown-btn:focus + .dropdown-content {
    display: block;
  }
  
  /* Iframe fix */
  iframe {
    width: 100vw !important;
    max-width: 100vw !important;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  
  /* Fix export buttons on mobile */
  .action-buttons {
    flex-direction: column;
    gap: 8px;
  }
  
  .action-btn {
    width: 100%;
  }
  
  /* Adjust page break indicators for mobile view */
  .page-break-indicator {
    transform: scale(0.55);
    transform-origin: top left;
  }
  
  /* Scale page overflow indicator for mobile */
  .page-overflow-indicator {
    padding: 4px 8px;
    font-size: 12px;
    margin-top: 8px;
    margin-left: 0;
    width: 100%;
    justify-content: center;
  }
  
  .multi-page-warning {
    font-size: 12px;
    padding: 6px 10px;
  }
}

/* Additional rules to remove borders from resume container and iframe content */
/* Remove borders from resume container in all contexts */
div[style*="max-width: 8.5in"],
.resume-container {
  border: none !important;
  box-shadow: none !important;
}

/* Ensure the iframe content also has no border */
.gjs-frame-wrapper div[style*="max-width: 8.5in"],
.gjs-frame-wrapper .resume-container {
  border: none !important;
  box-shadow: none !important;
}

/* Override any print styles that might add borders */
@media print {
  div[style*="max-width: 8.5in"],
  .resume-container {
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
  }
}

/* New Additions */

/* Page break visualization styles */
.page-break-indicator {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  height: 2px !important;
  background: repeating-linear-gradient(to right, #cc0000, #cc0000 10px, transparent 10px, transparent 20px) !important;
  z-index: 1000 !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  pointer-events: none !important;
}

.page-break-label {
  position: absolute !important;
  right: 10px !important;
  top: -20px !important;
  background: #ffffff !important;
  color: #cc0000 !important;
  font-size: 12px !important;
  font-weight: bold !important;
  padding: 2px 8px !important;
  border: 1px solid #cc0000 !important;
  border-radius: 4px !important;
  z-index: 1001 !important;
  pointer-events: none !important;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1) !important;
}

/* Text scaling controls styles */
.text-scale-controls {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 6px 10px;
  margin-left: 10px;
}

.scale-btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #007bff;
  background: white;
  color: #007bff;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px;
}

.scale-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.scale-btn:hover:not(:disabled) {
  background: #f0f8ff;
}

.scale-value {
  font-size: 14px;
  font-weight: bold;
  margin: 0 8px;
  min-width: 40px;
  text-align: center;
}

.scale-toggle-btn {
  background-color: #ff9800;
}

.scale-toggle-btn:hover {
  background-color: #f57c00;
}

/* YOUR ADDED PRINT CSS - will be applied on all devices */
@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 0;
    padding: 0;
  }
  .page-break-indicator,
  .page-break-label {
    display: none !important;
  }
}

.reset-btn {
  background-color: #ff9800;
}

.reset-btn:hover {
  background-color: #f57c00;
}

/* Updated Button Styles for Career Genius - Top Buttons Only */

/* Action buttons (Reset Resume, Choose Template, Download PDF) */
.action-btn, 
button.action-btn,
.reset-btn, 
.template-selector-btn, 
#downloadPdfButton,
.format-btn,
.dropdown-btn {
  padding: 10px 18px;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.25s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  position: relative;
  overflow: hidden;
  color: white;
}

/* Modern hover effect */
.action-btn:hover,
button.action-btn:hover, 
.reset-btn:hover, 
.template-selector-btn:hover, 
#downloadPdfButton:hover,
.format-btn:hover,
.dropdown-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.action-btn:active,
button.action-btn:active,
.reset-btn:active, 
.template-selector-btn:active, 
#downloadPdfButton:active,
.format-btn:active,
.dropdown-btn:active {
  transform: translateY(0);
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

/* Color variations for different button types */
.reset-btn, button.reset-btn {
  background-color: #ff9800;
}

.reset-btn:hover, button.reset-btn:hover {
  background-color: #f57c00;
}

.template-selector-btn, button.template-selector-btn {
  background-color: #6c6cff;
}

.template-selector-btn:hover, button.template-selector-btn:hover {
  background-color: #5858e6;
}

/* Download PDF button */
#downloadPdfButton, 
button#downloadPdfButton,
.format-btn,
.dropdown-btn {
  background-color: #007bff;
}

#downloadPdfButton:hover, 
button#downloadPdfButton:hover,
.format-btn:hover,
.dropdown-btn:hover {
  background-color: #0069d9;
}

/* Button icon spacing (for icons inside buttons) */
.action-btn i, 
.reset-btn i, 
.template-selector-btn i, 
#downloadPdfButton i,
.format-btn i,
.dropdown-btn i {
  margin-right: 8px;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .action-btn, 
  button.action-btn,
  .reset-btn, 
  .template-selector-btn, 
  #downloadPdfButton,
  .format-btn,
  .dropdown-btn {
    padding: 8px 14px;
    font-size: 14px;
    width: 100%; /* Full width on mobile */
  }
  
  .action-buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
}

/* Improved Header Styles for Career Genius */

/* Main header container */
.action-header {
  background-color: white;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #eaeaea;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

/* Desktop header layout */
.desktop-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Mobile header layout */
.mobile-header {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

/* Professional AI Resume Builder heading */
.resume-builder-heading {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  color: #1a1a1a;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  position: relative;
}

/* Add accent color for "AI" part */
.resume-builder-heading .ai-text {
  color: #007bff;
  font-weight: 700;
  position: relative;
}

/* Small indicator dot for AI */
.resume-builder-heading .ai-text::after {
  content: '';
  position: absolute;
  top: -2px;
  right: -8px;
  width: 6px;
  height: 6px;
  background-color: #007bff;
  border-radius: 50%;
}

/* Optional logo/icon for the header */
.resume-builder-icon {
  margin-right: 12px;
  font-size: 22px;
  color: #007bff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .action-header {
    padding: 1.25rem 1rem;
  }
  
  .resume-builder-heading {
    font-size: 22px;
    margin-bottom: 0.5rem;
  }
  
  .action-buttons {
    margin-top: 10px;
    width: 100%;
  }
}

.subscription-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cancel-subscription-btn {
  background-color: transparent;
  color: #dc3545;
  border: 1px solid #dc3545;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancel-subscription-btn:hover {
  background-color: #dc3545;
  color: white;
}

.subscription-status {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
}

.subscription-status.canceled {
  background-color: #fff3cd;
  color: #856404;
}

.status-icon {
  font-size: 16px;
}


