/* Main Container */
.social-proof-container {
    width: 100%;
    text-align: center;
    padding: 10px; /* Reduced padding to minimize space */
    background-color: #fff;
    
  }
  
  /* Modal-Specific Styling */
  .modal-social-proof {
    max-height: 180px; /* Adjust as needed for modal */
    overflow: hidden;
    padding: 6px;      /* Smaller padding in modal */
  }
  
  /* Heading (only visible on larger screens if not in modal) */
  .social-proof-heading {
    font-size: 1.6rem;    /* Slightly smaller heading */
    font-weight: bold;
    margin-bottom: 10px;  /* Reduced gap */
    color: #333;
  }
  
  /* Rating Summary */
  .rating-summary {
    margin: 0 auto 10px auto; /* Reduced bottom margin */
    max-width: 500px;
  }
  
  .rating-text {
    font-size: 1.1rem;  /* Slightly smaller text */
    font-weight: 600;
    color: #28a745;
    margin-bottom: 4px; /* Less space */
  }
  
  .star-rating {
    display: inline-flex;
    margin-bottom: 3px;
  }
  
  .rating-score {
    font-size: 0.95rem;
    font-weight: bold;
    color: #333;
  }
  
  .rating-subtext {
    font-size: 0.85rem;
    color: #666;
    margin-top: 2px;
  }
  
  /* Reviews Container */
  .reviews-container {
    display: flex;
    justify-content: center;
    gap: 8px;           /* Smaller gap between cards */
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    min-height: 120px;  /* Lower min-height so there's less blank space */
  }
  
  /* Review Card (default) */
  .review-card {
    background-color: #f8f9fa;
    border-radius: 6px;
    padding: 8px;       /* Less vertical space */
    text-align: left;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    width: 220px;
    margin-bottom: 0;   /* No extra gap under each card */
    line-height: 1.2;   /* Tighter line spacing */
  }
  
  .review-card h4 {
    font-size: 0.85rem;
    color: #333;
    margin-bottom: 2px;
  }
  
  .review-date {
    font-size: 0.75rem;
    color: #777;
    margin-top: 0;
    margin-bottom: 2px;
  }
  
  .review-stars {
    margin-bottom: 3px;
  }
  
  .review-comment {
    font-size: 0.8rem;
    color: #444;
    margin-bottom: 0;
  }
  
  /* Responsive: When only one review is shown (small screen or modal) */
  @media (max-width: 600px) {
    .reviews-container {
      flex-direction: column;
      align-items: center;
    }
    .review-card {
      width: 100%;
      max-width: 280px;
      padding: 10px;
    }
  }
  
  /* Modal-Specific: Make review cards fill most of the modal width and be half as big */
  .modal-social-proof .review-card {
    width: 90%;            /* Fills more horizontal space */
    margin: 0 auto;        /* Center within the modal */
    padding: 4px 6px;      /* Even less padding */
    border-radius: 3px;    /* Smaller corner radius */
    /* Optionally, reduce the line-height further if needed */
  }
  
  .modal-social-proof .review-card h4 {
    font-size: 0.7rem;
  }
  
  .modal-social-proof .review-date {
    font-size: 0.65rem;
  }
  
  .modal-social-proof .review-stars {
    margin-bottom: 2px;
  }
  
  .modal-social-proof .review-comment {
    font-size: 0.7rem;
    line-height: 1.1;
    margin-bottom: 0;
  }
  

  /* Add this to your SocialProof.css file */

/* Prevent modal appearance on initial load */
.social-proof-container:not(.modal-social-proof) {
  max-height: none !important;
  overflow: visible !important;
  padding: 10px !important;
}

.social-proof-container:not(.modal-social-proof) .reviews-container {
  display: flex !important;
  flex-wrap: wrap !important;
  position: static !important;
}

.social-proof-container:not(.modal-social-proof) .review-card {
  position: relative !important;
  flex: 1 1 220px !important;
  max-width: 300px !important;
  margin: 0 8px 16px !important;
  left: auto !important;
  right: auto !important;
}

/* Disable all transitions during initial page load */
.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

/* Add this script to your main app component or index.js:
document.body.classList.add('preload');
window.addEventListener('load', function() {
  setTimeout(function() {
    document.body.classList.remove('preload');
  }, 500);
});
*/