/* Basic factor-card styling */
.factor-card {
  background: #f8f9fa;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}

/* Low score: Red highlight */
.factor-card.low-score {
  background: rgba(255, 68, 68, 0.1);
  border-left: 6px solid #ff4444;
}

/* Medium score: Yellow highlight */
.factor-card.medium-score {
  background: rgba(255, 187, 51, 0.1);
  border-left: 6px solid #ffbb33;
}

/* High score: Green highlight */
.factor-card.high-score {
  background: rgba(0, 204, 136, 0.1);
  border-left: 6px solid #00cc88;
}

.factor-card h3 {
  margin: 0 0 8px 0;
  font-size: 16px;
  color: #333;
  font-weight: 600;
}

.factor-card p {
  margin: 0;
  font-size: 14px;
  color: #555;
  line-height: 1.4;
}


