.job-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    background: linear-gradient(135deg, #f3f4f6, #ffffff);
    padding: 20px;
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.logo {
    max-width: 180px;
}

.job-details-form {
    background: #ffffff;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 450px;
    text-align: center;
}

.form-title {
    font-size: 22px;
    font-weight: bold;
    color: #333;
}

.form-description {
    font-size: 14px;
    color: #555;
    margin-bottom: 20px;
}

.input-group {
    margin-bottom: 15px;
    text-align: left;
}

.input-label {
    font-size: 14px;
    font-weight: bold;
    color: #444;
    margin-bottom: 5px;
    display: block;
}

.input-field {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
}

.input-field:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
}


/* JobDetails.css */
.job-details-container .ai-output-box {
    max-height: 200px;  /* Set a max height for scrolling */
    overflow-y: auto;  /* Enable vertical scrolling */
    background-color: #f4f4f4;  /* Light grey background */
    border: 1px solid #ccc;  /* Subtle border */
    padding: 10px;  /* Padding for text inside */
    margin-top: 10px;  /* Space above the box */
    border-radius: 5px;  /* Rounded corners for modern feel */
    font-size: 0.9rem;  /* Smaller font size for more content */
    line-height: 1.4;  /* Improved line spacing */
    color: #333;  /* Darker text for better readability */
}
.spinner {
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    /* Don’t disable scrolling with overflow: hidden unless absolutely necessary */
  }
  

  

  /* Container for each result section (scraped data, AI details, etc.) */
.scraped-results,
.ai-results,
.pay-range-guess {
  margin-top: 20px;
  padding: 15px;
  background: #f9f9f9; /* Light background */
  border-radius: 8px;
  text-align: left; /* Align content to the left inside these boxes */
  border: 1px solid #eee; /* Subtle border */
}

/* Headings within those sections */
.scraped-results h3,
.ai-results h3,
.pay-range-guess h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: #333;
}

/* Style the 'Proceed' button so it stands out */
.proceed-btn {
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  padding: 14px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background: linear-gradient(135deg, #28a745, #218838);
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.proceed-btn:hover {
  background: linear-gradient(135deg, #218838, #1e7e34);
}

/* Keep or adjust your existing .submit-btn if you want to differentiate them */
.submit-btn {
  width: 100%;
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  font-size: 16px;
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 10px; /* Add a little spacing if needed */
}

.submit-btn:hover {
  background: linear-gradient(135deg, #0056b3, #003c7a);
}

/* Optional: Tweak the box for the AI text output */
.ai-output-box {
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff; 
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  line-height: 1.4;
  color: #333;
}



.fallback-toggle-btn {
    margin-top: 10px;
    background: none;
    border: none;
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.85rem;
    display: block; /* So it appears on its own line */
    margin-left: auto;
    margin-right: auto;
  }
  .fallback-toggle-btn:hover {
    color: #0056b3;
  }
  
  /* CSS to center the job details container */

/* Update the container to properly center content */
.job-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto; /* This centers the container horizontally */
  min-height: calc(100vh - 60px); /* Account for header height */
}

/* Center the form itself */
.job-details-form {
  background: #ffffff;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px; /* Slightly wider form */
  text-align: center;
  margin: 0 auto; /* Center horizontally */
}

/* Center the Career Genius logo and text */
.career-genius-center {
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}

/* Center the progress bar */
.progress-bar-container {
  margin: 20px auto;
  max-width: 500px;
  width: 100%;
}

/* Center the footer sections */
.featured-employers,
.social-proof {
  margin: 40px auto;
  text-align: center;
  max-width: 1000px;
  width: 100%;
}

/* Add to ResumeCritiquePage.css */
.highlight {
  color: #007bff;
  font-weight: bold;
  position: relative;
}

/* Blue dot for highlighted text */
.highlight::after {
  content: '';
  position: absolute;
  top: -2px;
  right: -8px;
  width: 6px;
  height: 6px;
  background-color: #007bff;
  border-radius: 50%;
}