.price {
  text-decoration: line-through;
  color: grey;
  margin-right: 10px;
  font-size: 20px;
}
.percentageDiscount {
  color: red; 
}

/* .discounted-price {
   color: red; 
  font-weight: bold;
} */

.btn-outline-primary {
    color: #0070ba;   
  }

   .btn-outline-primary:hover {
    color: #0070ba;
    border-color: #0070ba;
    background-color: #0070ba;
  } 


  .customer-support {
    font-size: 10px;
    /* border: 5px solid #0070ba; */
    padding: 10px;
  }


  .paypal-button {
    flex-grow: 1;
    padding: '20px';
    width: '100%';
  }
  