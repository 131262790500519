/* CompactProgressBar.css */
.compact-progress-container {
  width: 100%;
  margin: 20px 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

/* Progress steps container */
.compact-progress-steps {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  gap: 8px;
  width: 100%;
}

/* Individual step button with text that adjusts to fit */
.compact-progress-step {
  flex: 1;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 8px 5px;
  font-weight: normal;
  font-size: 12px;
  color: #495057;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  z-index: 2;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  overflow: visible; /* Allow content to be visible */
  word-break: normal; /* Prevent word breaking */
  hyphens: none; /* Prevent hyphenation */
  min-width: 0; /* Important for flex items */
  white-space: normal; /* Allow text to wrap naturally */
  line-height: 1.2; /* Tighter line height */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px; /* Fixed height */
}

.compact-progress-step:hover {
  background-color: #f1f3f5;
  border-color: #c1c9d0;
}

/* Active step styling */
.compact-progress-step.active {
  background-color: #4285f4;
  color: white;
  border-color: #3367d6;
  font-weight: 500;
  box-shadow: 0 2px 5px rgba(66, 133, 244, 0.3);
}

/* Completed step styling */
.compact-progress-step.completed {
  background-color: #e8f0fe;
  color: #1a73e8;
  border-color: #c2d7ff;
}

/* Progress bar track */
.compact-progress-track {
  width: 100%;
  height: 6px;
  background-color: #e9ecef;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}

/* Progress bar fill */
.compact-progress-fill {
  height: 100%;
  background: linear-gradient(to right, #4285f4, #34a853);
  border-radius: 3px;
  transition: width 0.5s ease;
}

/* Step content wrapper to properly control text scaling */
.step-content {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 12px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .step-content {
    font-size: 11px;
  }
}

@media (max-width: 576px) {
  .compact-progress-step {
    padding: 6px 4px;
    height: 30px;
  }
  
  .step-content {
    font-size: 10px;
  }
  
  /* Use numbers only on very small screens */
  .step-number {
    display: inline;
  }
  
  .step-label {
    display: none;
  }
}

/* On wider screens, ensure the text doesn't overflow */
@media (min-width: 992px) {
  .compact-progress-step {
    padding: 8px 10px;
    height: 40px;
  }
  
  .step-content {
    font-size: 13px;
  }
}