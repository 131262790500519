.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    
    display: flex;
    justify-content: center;
    align-items: center;
}


.popup-inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #257883;

}

.popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    
}


.modal-body {
    height: calc(100vh - 5em);
    overflow-y: auto;
}


.blink {
    animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0.2;
    }
  }

  .copyToClipboardImg {
    height: 30px;
    width: 30px; 
    justify-content: left;
}

.copyToClipboardImg:hover {
    height: 35px;
    width: 35px; 
  }