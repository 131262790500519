body {
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
    padding: 0;
  }

  .container {
    max-width: 800px;
    margin: 0 auto;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  ol {
    margin-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }

  .bold {
    font-weight: bold;
  }