.progress-ring {
  width: 100px;
  height: 100px;
  display: block;
  margin: 0 auto;
}

.progress-ring__background {
  stroke: #e6e6e6;
  fill: transparent;
}

.progress-ring__progress {
  /* Removed transition here since we're using requestAnimationFrame */
  fill: transparent;
}

.progress-ring text {
  font-size: 20px;
  font-weight: bold;
  text-anchor: middle;
  fill: #333;
}