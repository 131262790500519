/* ===========================
   GLOBAL STYLES
   =========================== */

/* Subtle background for the entire page */
body {
    background: #f9f9f9;
    margin: 0;
    font-family: "Helvetica Neue", Arial, sans-serif;
  }
  
  /* ===========================
     CRITIQUE CONTAINER
     =========================== */
  
  /* Main container for the critique module */
  .critique-container {
    max-width: 700px;
    margin: 40px auto;
    background: linear-gradient(to bottom, #f8f9fa, #ffffff);
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* Page title inside the critique container */
  .critique-container h1 {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 24px;
  }
  
  /* ===========================
     ACTION BUTTONS (If needed)
     =========================== */
  
  .action-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 24px;
  }
  
  .action-btn,
  .fetch-critique-btn {
    background: #007bff;
    color: #fff;
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .action-btn:hover,
  .fetch-critique-btn:hover {
    background: #0056b3;
  }
  
  /* ===========================
     FACTOR CARDS + ANIMATIONS
     =========================== */
  
  .factor-card {
    background: #f8f9fa;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
  
    /* Start hidden and moved down */
    opacity: 0;
    transform: translateY(20px);
  
    /* Fade+slide up over 0.8s */
    animation: fadeInUp 0.8s ease-in-out forwards;
  }
  
  /* Stagger each factor card for a nice sequence effect */
  .factor-card:nth-child(1) {
    animation-delay: 0.5s;
  }
  .factor-card:nth-child(2) {
    animation-delay: 1s;
  }
  .factor-card:nth-child(3) {
    animation-delay: 1.5s;
  }
  .factor-card:nth-child(4) {
    animation-delay: 2s;
  }
  .factor-card:nth-child(5) {
    animation-delay: 2.5s;
  }
  
  /* Keyframe for fade+slide up */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Factor-card headings & paragraphs */
  .factor-card h3 {
    font-size: 20px;
    color: #222;
    margin: 0 0 8px;
  }
  .factor-card p {
    font-size: 15px;
    color: #555;
    margin: 0;
    line-height: 1.4;
  }
  
  /* ===========================
     PROGRESS RING & SCORE TEXT
     =========================== */
  
  .progress-ring {
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }
  
  .progress-ring__progress {
    transition: stroke-dashoffset 1s ease-in-out;
  }
  
  .score-text {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
  }
  
  /* ===========================
     FADE-OUT UTILITY CLASS
     =========================== */
  
  .fade-out {
    opacity: 0;
    transition: opacity 1s ease-in-out;
    pointer-events: none; /* Prevent interaction while fading out */
  }
  
  /* ===========================
     MODAL OVERLAY & CONTENT
     =========================== */
  
  /* Modal Overlay (flexbox center, dark backdrop) */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: none; /* default hidden */
    align-items: center;
    justify-content: center;
    padding: 16px;
    box-sizing: border-box;
  
    /* Default darker overlay (you can adjust or remove) */
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  /* Softer overlay when .show is added */
  .modal-overlay.show {
    display: flex;
    background-color: rgba(0, 0, 0, 0.25);
  }
  
  /* Modal content area: central card with scroll if too tall */
  .modal-content {
    position: relative;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    max-width: 450px;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    text-align: center;
  }
  
  /* Close button (top-right corner) */
  .modal-close {
    background: none;
    border: none;
    font-size: 22px;
    color: #888;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
  }
  .modal-close:hover {
    color: #333;
  }
  
  /* Modal headings & paragraphs */
  .modal-content h2 {
    color: #2c3e50; /* Deep modern blue */
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .modal-content p {
    font-size: 16px;
    color: #555;
    margin: 5px 0 12px;
    line-height: 1.4;
  }
  
  /* Responsive tweak for smaller screens */
  @media (max-width: 400px) {
    .modal-content {
      padding: 16px;
    }
    .modal-content h2 {
      font-size: 1.1rem;
    }
    .modal-content p {
      font-size: 0.9rem;
    }
  }
  
  /* ===========================
     CTA BUTTONS
     =========================== */
  
  /* Primary CTA (Modern) */
  .primary-cta-modern {
    background: linear-gradient(135deg, #0052cc, #003f91);
    color: #fff;
    padding: 14px 18px;
    font-size: 17px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease-in-out, transform 0.2s ease-in-out;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }
  .primary-cta-modern:hover {
    background: linear-gradient(135deg, #0041a3, #002c6a);
    transform: scale(1.03);
  }
  
  /* Additional CTA variant (e.g., PayPal, Debit) */
  .primary-cta {
    display: inline-block;
    margin: 20px 0;
    padding: 14px 20px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background: linear-gradient(135deg, #d93025, #b32c1e);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .primary-cta:hover {
    background: linear-gradient(135deg, #b32c1e, #99271a);
    transform: scale(1.03);
  }
  
  /* Payment Buttons */
  .paypal-btn {
    width: 100%;
    background: #0070ba;
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 12px;
    border-radius: 6px;
    transition: background 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  .paypal-btn:hover {
    background: #005ea6;
  }
  .paypal-btn img {
    height: 18px;
  }
  
  /* Debit/Credit Button */
  .debit-btn {
    width: 100%;
    background: #222;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 12px;
    border-radius: 6px;
    margin-top: 10px;
    transition: background 0.2s ease-in-out;
  }
  .debit-btn:hover {
    background: #444;
  }
  
  /* ===========================
     MISC STYLES
     =========================== */
  
  .sales-pitch-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-height: 80vh; /* Prevents modal from exceeding screen height */
    overflow: hidden;
  }
  
  .sales-pitch-buttons {
    width: 100%;
    position: relative;
    bottom: 0;
    padding-top: 10px;
  }
  
  /* Additional utility classes if needed */
  .weak-badge {
    background-color: #ff4444;
    color: #fff;
    font-size: 0.75rem;
    padding: 3px 6px;
    border-radius: 4px;
    margin-left: 10px;
  }
  
  /* Low score factor card highlight */
  .factor-card.low-score {
    background: rgba(255, 68, 68, 0.05);
    border-left: 6px solid #ff4444;
  }
  .low-score-warning {
    color: #ff4444;
    font-weight: bold;
    font-size: 0.85rem;
  }
  
  /* Soft warning text */
  .lost-salary {
    color: #d93025; /* Warm orange/red */
    font-size: 14px;
    margin-top: 5px;
    font-weight: bold;
    text-align: center;
  }
  