/* Main container */
.featured-employers-container {
    width: 100%;
    text-align: center;
    padding: 40px 20px;
    background-color: #f8f9fa; /* Light background for the full version */
  }
  
  /* Title (hidden in modal) */
  /* Title (default size) */
  .featured-title {
    font-size: 1.5rem;  /* Reduce from 1.8rem */
    font-weight: 600;
    margin-bottom: 16px;
    color: #333
}
  
  /* Logos row */
  .logos-row {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  
  .logos-row img {
    max-height: 40px;
    width: auto;
    object-fit: contain;
    transition: transform 0.2s ease-in-out;
    opacity: 0.8;
  }
  
  .logos-row img:hover {
    transform: scale(1.05);
    opacity: 1;
  }
  
  /* Responsive tweak for full view */
  @media (max-width: 600px) {
    .featured-title {
      font-size: 1.4rem;
    }
    .logos-row img {
      max-height: 30px;
    }
  }
  
  /* 
    Modal-Specific Styles 
    (only applied when 'inModal' is true via .modal-featured class)
  */
  .modal-featured {
    background-color: transparent; /* or keep it white if you prefer */
    padding: 10px; /* smaller padding */
  }
  
  .modal-featured .featured-title {
       font-size: 0.8rem;  /* Reduce from 1.8rem */
       font-weight: 600;
       margin-bottom: 16px;
       color: #333
  } 
  
  .modal-featured .logos-row {
    gap: 12px;
  }
  
  .modal-featured .logos-row img {
    max-height: 25px; /* much smaller for the modal */
    opacity: 0.9;
  }
  
