/* LetterGeneratorPage.css */

.letter-generator-page {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
  }
  
  /* Header styling - cleaner and more minimal */
  .letter-generator-header {
    background-color: white;
    padding: 1.25rem;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  /* Title styling */
  .letter-builder-heading {
    margin: 0;
    font-size: 1.75rem;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  
  /* Blue accent for "Generator" text */
  .letter-builder-heading .ai-text {
    color: #4169E1;
    position: relative;
  }
  
  /* Small dot after the word */
  .letter-builder-heading .ai-text::after {
    content: '';
    position: absolute;
    top: 0;
    right: -10px;
    width: 6px;
    height: 6px;
    background-color: #4169E1;
    border-radius: 50%;
  }
  
  /* Action buttons container */
  .action-buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  /* Base button styling */
  .action-btn {
    padding: 12px 16px;
    border: none;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-decoration: none;
    min-width: 140px;
  }
  
  .action-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .action-btn:active {
    transform: translateY(0);
  }
  
  /* Icons in buttons */
  .action-btn i {
    margin-right: 8px;
  }
  
  /* Button color variations */
  .regenerate-btn {
    background-color: #7C4DFF; /* Purple */
  }
  .regenerate-btn:hover {
    background-color: #6E45E2;
  }
  
  .download-btn {
    background-color: #4CAF50; /* Green */
  }
  .download-btn:hover {
    background-color: #43A047;
  }
  
  .generate-letter-btn {
    background-color: #2196F3; /* Blue */
  }
  .generate-letter-btn:hover {
    background-color: #1E88E5;
  }
  
  .generate-hm-letter-btn {
    background-color: #FF9800; /* Orange */
  }
  .generate-hm-letter-btn:hover {
    background-color: #F57C00;
  }
  
  /* Disabled button state */
  .action-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  
  /* Premium badge */
  .premium-badge {
    display: flex;
    align-items: center;
    background-color: #4CAF50;
    color: white;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
  }
  
  .subscription-btn {
    background: linear-gradient(135deg, #FF9800, #FF5722);
    color: white;
    border: none;
    border-radius: 6px;
    padding: 12px 16px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
  }
  
  .subscription-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
  }
  
  .cancel-subscription-btn {
    background-color: transparent;
    color: #f44336;
    border: 1px solid #f44336;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-left: 8px;
  }
  
  .cancel-subscription-btn:hover {
    background-color: #f44336;
    color: white;
  }
  
  /* Error message styling */
  .error-message {
    background-color: #fff3cd;
    color: #856404;
    padding: 10px 15px;
    border-radius: 4px;
    margin-top: 10px;
    border-left: 4px solid #ffeeba;
    width: 100%;
  }
  
  .error-message.error {
    background-color: #f8d7da;
    color: #721c24;
    border-left-color: #f5c6cb;
  }
  
  /* Loading overlay */
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Letter editor container */
  .letter-editor-container {
    flex: 1;
    padding: 2rem;
    display: flex;
    justify-content: center;
  }
  
  /* Textarea styling */
  .letter-textarea {
    width: 100%;
    max-width: 800px;
    min-height: 500px;
    padding: 1.5rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
    line-height: 1.6;
    resize: vertical;
  }
  
  .letter-textarea:focus {
    outline: none;
    border-color: #4169E1;
    box-shadow: 0 0 0 3px rgba(65, 105, 225, 0.1);
  }
  
  /* Footer */
  .letter-generator-footer {
    background-color: white;
    padding: 1rem;
    border-top: 1px solid #eaeaea;
    text-align: center;
    font-size: 0.85rem;
    color: #6c757d;
    margin-top: auto;
  }
  
  /* Mobile optimizations */
  @media (max-width: 768px) {
    .letter-generator-header {
      flex-direction: column;
      align-items: stretch;
      padding: 1rem;
    }
    
    .letter-builder-heading {
      margin-bottom: 15px;
      font-size: 1.5rem;
    }
    
    .action-buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      width: 100%;
    }
    
    .action-btn {
      width: 100%;
      min-width: unset;
      padding: 12px 8px;
      font-size: 14px;
      border-radius: 8px;
    }
    
    .action-btn i {
      margin-right: 6px;
      font-size: 14px;
    }
    
    .letter-editor-container {
      padding: 1rem;
    }
    
    .letter-textarea {
      min-height: 60vh;
      padding: 1rem;
    }
    
    /* For mobile, subscription button spans full width */
    .subscription-container {
      grid-column: span 2;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 8px;
    }
    
    .subscription-btn {
      width: 100%;
      justify-content: center;
      grid-column: span 2;
      margin-top: 8px;
    }
    
    /* Error message mobile styles */
    .error-message {
      margin: 10px 0;
      padding: 8px 12px;
      font-size: 14px;
    }
  }
  
  /* Subscription status */
  .subscription-container {
    display: flex;
    align-items: center;
  }
  
  .subscription-status {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
  }
  
  .subscription-status.canceled {
    background-color: #fff3cd;
    color: #856404;
  }
  
  /* Progress bar styling */
  .progress-bar-container {
    padding: 1.5rem;
    background-color: white;
    border-bottom: 1px solid #eaeaea;
  }
  
  .progress-steps {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;
    max-width: 800px;
  }
  
  .progress-step {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #e9ecef;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #6c757d;
    z-index: 2;
  }
  
  .progress-step.active {
    background-color: #4169E1;
    color: white;
  }
  
  .progress-step.completed {
    background-color: #4CAF50;
    color: white;
  }
  
  .progress-line {
    position: absolute;
    height: 3px;
    background-color: #e9ecef;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 1;
  }
  
  .progress-line-fill {
    position: absolute;
    height: 3px;
    background-color: #4CAF50;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: 1;
  }
  
  .progress-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    max-width: 800px;
    margin: 8px auto 0;
  }
  
  .progress-label {
    font-size: 12px;
    color: #6c757d;
    text-align: center;
    max-width: 100px;
  }
  
  .progress-label.active {
    color: #4169E1;
    font-weight: 600;
  }
  
  .progress-label.completed {
    color: #4CAF50;
    font-weight: 600;
  }
  
  @media (max-width: 768px) {
    .progress-bar-container {
      padding: 1rem;
    }
    
    .progress-step {
      width: 24px;
      height: 24px;
      font-size: 12px;
    }
    
    .progress-label {
      font-size: 10px;
      max-width: 70px;
    }
  }