/* StripeSubscriptionButton.css */

/* Common styles */
.subscription-btn-pro,
.cancel-subscription-btn-pro {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 10px 16px;
  white-space: nowrap;
  font-size: 14px;
}

/* Primary subscription button */
.subscription-btn-pro {
  background: linear-gradient(to bottom, #5e72e4, #4a5bd9);
  color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.subscription-btn-pro:hover {
  background: linear-gradient(to bottom, #4a5bd9, #3a4ac9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-1px);
}

.subscription-btn-pro:active {
  transform: translateY(1px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

/* Cancel subscription button */
.cancel-subscription-btn-pro {
  background-color: #f8f9fa;
  color: #495057;
  border: 1px solid #ced4da;
}

.cancel-subscription-btn-pro:hover {
  background-color: #e9ecef;
  border-color: #adb5bd;
}

/* Premium badge styles */
.subscription-container-pro {
  display: flex;
  align-items: center;
  gap: 10px;
}

.premium-badge-pro {
  display: flex;
  align-items: center;
  background-color: #f1f5ff;
  color: #4a5bd9;
  padding: 8px 12px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #d9e2fc;
}

.checkmark-icon {
  margin-right: 6px;
  color: #5e72e4;
}

/* Subscription status styles */
.subscription-status-pro {
  background-color: #fff7ea;
  border: 1px solid #ffe0b2;
  border-radius: 4px;
  padding: 8px 12px;
}

.status-content {
  display: flex;
  align-items: center;
  color: #f57c00;
  font-size: 14px;
  font-weight: 500;
}

.status-icon {
  margin-right: 6px;
}

/* Loading state */
.btn-loading-pro {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-pro {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 0.8s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Icon styling */
.lock-icon {
  margin-right: 8px;
}

/* Disabled state */
.subscription-btn-pro:disabled {
  background: #a0a0a0;
  cursor: not-allowed;
  box-shadow: none;
}