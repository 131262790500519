/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
  height: 100%;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* items at the top instead of center */
  min-height: 100vh;
  padding: 0 20px 0; /* remove bottom padding */
  background: linear-gradient(135deg, #f3f4f6, #ffffff);
}


/* Header Styling */
.header {
  width: 100%;
  text-align: center;
  margin: 0 0 20px 0; /* No top margin */
}

.logo {
  max-width: 180px;
  display: block;
  margin: 0 auto;
}

/* Form Container (similar to job-details-form) */
.form-container {
  background: #ffffff;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 450px;
  text-align: center;
}

/* Upload instructions */
.upload-note {
  font-size: 14px;
  color: #555;
  margin-bottom: 15px;
}

/* Input Group */
.input-group {
  margin-bottom: 15px;
  text-align: left;
}

.input-label {
  font-size: 14px;
  font-weight: bold;
  color: #444;
  margin-bottom: 5px;
  display: block;
}

.input-field {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.input-field:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
}

/* Upload Box */
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #007bff;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.upload-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-icon {
  width: 80px;
  transition: transform 0.3s ease-in-out;
}

.upload-text {
  font-size: 16px;
  color: #007bff;
  margin-top: 10px;
  transition: color 0.3s ease-in-out;
}

.upload-container:hover {
  background-color: rgba(0, 123, 255, 0.1);
  border-color: #0056b3;
  transform: scale(1.05);
}

.upload-container:hover .upload-icon {
  transform: scale(1.2);
}

.upload-container:hover .upload-text {
  color: #0056b3;
}

/* Resume Info Row */
.resume-info-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  background-color: #f8f8f8;
  padding: 0.75rem 1rem;
  border-radius: 4px;
}

.resume-filename {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

/* Delete Button: shows only a red "X" */
.delete-btn {
  background: none;
  border: none;
  cursor: pointer;
}
.delete-btn .delete-icon {
  font-size: 1.5rem;
  color: red;
}

/* Submit Buttons */
.btn.upload-btn,
.submit-btn {
  width: 100%;
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  font-size: 16px;
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 10px;
}

.btn.upload-btn:hover,
.submit-btn:hover {
  background: linear-gradient(135deg, #0056b3, #003c7a);
}

/* Fallback Toggle Button (if needed) */
.fallback-toggle-btn {
  margin-top: 10px;
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.85rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.fallback-toggle-btn:hover {
  color: #0056b3;
}

/* Progress Bar styles can remain as in your JobDetails.css if shared */

/* Add to ResumeCritiquePage.css */
.highlight {
  color: #007bff;
  font-weight: bold;
  position: relative;
}

/* Blue dot for highlighted text */
.highlight::after {
  content: '';
  position: absolute;
  top: -2px;
  right: -8px;
  width: 6px;
  height: 6px;
  background-color: #007bff;
  border-radius: 50%;
}