.App {
  text-align: center;
}

.App-logo {
  height: 60vmin;
  pointer-events: none;
}

.header {
  
  color: '#57acba';
  
}
.full-width {
  width: 100%;
}

.green-background {
  background-color: green;
}

apple-pay-button:hover {
  background-color: #555;

}

#apple-pay-button:active {
  background-color: #222;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
apple-pay-button {
  --apple-pay-button-width: 150px;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 3px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
  

}

.btn:hover {
  color: rgb(0, 0, 0);
}

.standout-button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.CareerGeniusImg {
  height: 40px;
  width: 130px; 
}

.CareerGeniusImg:hover {
  height: 45px;
  width: 135px; 
}